import Carousel from "@itseasy21/react-elastic-carousel";
import componentsStyles from "../../styles/Components.module.css";

const CustomCarousel = ({
  children,
  paginationAndArrows,
  itemsToShowInitial,
}) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 1 },
    { width: 650, itemsToShow: 1 },
    { width: 800, itemsToShow: 2 },
    {
      width: 1000,
      itemsToShow: itemsToShowInitial,
      pagination: paginationAndArrows,
      showArrows: paginationAndArrows,
    },
  ];

  return (
    <Carousel
      className={componentsStyles.carousel}
      breakPoints={breakPoints}
      enableAutoPlay={true}
      autoPlaySpeed={5000}
      isRTL={false}
    >
      {children}
    </Carousel>
  );
};

export default CustomCarousel;
